import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import Home from '@/views/Home.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/convert',
    name: 'Convert',
    component: () => import(/* webpackChunkName: "console" */ '@/views/Convert.vue')
  },
  {
    path: '/write',
    name: 'Write',
    component: () => import(/* webpackChunkName: "console" */ '@/views/Write.vue')
  },
  {
    path: '/ocr',
    name: 'OCR',
    component: () => import(/* webpackChunkName: "console" */ '@/views/OCR.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
