
import { defineComponent } from 'vue';
import NavBar from '@/views/NavBar.vue'; // @ is an alias to /src
import { useMeta } from 'vue-meta'

export default defineComponent({
  name: 'Home',
  setup () {
    useMeta({
      title: 'Welcome to Transliterator',
      htmlAttrs: { lang: 'en', amp: true }
    })
  },
  components: {
    NavBar,
  },
});
